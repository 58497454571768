import { useStaticQuery, graphql } from "gatsby"

const useBlogPost = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryBlog {
        allContentfulBlog {
          nodes {
            title
            tags
            slug
            date
            description

            image {
              gatsbyImageData
            }
            childContentfulBlogBodyContentTextNode {
              childrenMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `
  )
  return data.allContentfulBlog.nodes.map(i => ({
    title: i.title,
    tags: i.tags,
    slug: i.slug,
    date: i.date,
    desc: i.description,
    body: i.childContentfulBlogBodyContentTextNode.childrenMarkdownRemark[0],
    image: i.image.gatsbyImageData,
  }))
}

export default useBlogPost
