import { Link } from "gatsby"
import React from "react"

import {
  IconApplePodcast,
  IconBrush,
  IconContribute,
  IconGooglePodcast,
  IconMic,
  IconSpotify,
  IconShare,
  IconDownload,
  IconCoffee,
} from "../atoms/Icons"

export const ListenSpotify = () => (
  <a
    aria-label="open spotify"
    href="https://open.spotify.com/show/4qTN4RuZRAH5UIIdRTiZxf?si=ba0739c461984d70"
  >
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md">
      <IconSpotify />
      <p className="mobile-paragraph text-left my-auto leading-5 pl-2">
        Listen The Juicy Pause <br />
        on <strong>Spotify</strong>
      </p>
    </button>
  </a>
)

export const ListenApple = () => (
  <a
    aria-label="open apple podcast"
    href="https://podcasts.apple.com/us/podcast/the-juicy-pause/id1612461085"
  >
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md">
      <IconApplePodcast />
      <p className="mobile-paragraph text-left my-auto leading-5 pl-2">
        Listen The Juicy Pause <br />
        on <strong> Apple Podcast</strong>
      </p>
    </button>
  </a>
)

export const ListenGoogle = () => (
  <a aria-label="open google podcast" href="/">
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md">
      <IconGooglePodcast />
      <p className="mobile-paragraph text-left my-auto leading-5 pl-2">
        Listen The Juicy Pause <br />
        on <strong>Google Podcast</strong>
      </p>
    </button>
  </a>
)

export const ButtonContribute = () => (
  <a
    aria-label="open google podcast"
    href="https://www.paypal.me/madameflore@yahoo.co.uk"
  >
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md mx-auto">
      <IconContribute />
      <p className="mobile-paragraph text-left my-auto leading-5 pl-2">
        Make your contribution <br />
        through <strong>PayPal</strong>
      </p>
    </button>
  </a>
)

export const ButtonContributeCoffee = () => (
  <a
    aria-label="open google podcast"
    href="https://www.buymeacoffee.com/madameflore"
  >
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md mx-auto">
      <IconCoffee />
      <p className="mobile-paragraph text-left my-auto leading-5 pl-2">
        Buy me a <strong>Coffee</strong>
      </p>
    </button>
  </a>
)

export const MeetDana = () => (
  <Link aria-label="open google podcast" to="/about">
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md">
      <IconMic />
      <p className="button-meet text-left my-auto leading-5 pl-2">Meet Dana</p>
    </button>
  </Link>
)

export const MeetArtist = () => (
  <a
    aria-label="open google podcast"
    href="https://www.barefootartes.com/dana-gallery"
  >
    <button className="flex items-center w-72 h-16 justify-center gap-4 border border-secondary rounded-lg min-w-sm text-secondary shadow-md">
      <IconBrush />
      <p className="button-meet text-left my-auto leading-5 pl-2">
        Meet Artist
      </p>
    </button>
  </a>
)

export const ButtonShare = ({ display, slug }) => (
  <button
    onClick={() => {
      navigator.clipboard.writeText(`${slug}`)
    }}
    className={` ${display} flex flex-col items-center justify-center hover:transform hover:scale-105`}
  >
    <IconShare />
    <p className="cards-cta text-center pt-0.5">
      Copy link to share <br /> with your friends
    </p>
  </button>
)
export const ButtonDownload = ({ slug }) => (
  <a aria-label="open google podcast" href={slug}>
    <button
      className={` flex flex-col items-center justify-center hover:transform hover:scale-105`}
    >
      <IconDownload />
      <p className="cards-cta text-center pt-0.5">
        Download <br />
        more info
      </p>
    </button>
  </a>
)
