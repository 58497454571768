import React from "react"

import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { IconAdd } from "../atoms/Icons"

import { ButtonDownload, ButtonShare } from "./Buttons"

export const CardHorizontalEvents = ({
  title,
  heading,
  body,
  image,
  slug,
  setDisplay,
}) => {
  return (
    <div className="card-horizontal shadow-md hidden lg:flex">
      <div className="w-full">
        <GatsbyImage
          height={262}
          image={image}
          alt="image card"
          placeholder="tracedSVG"
          className="rounded-l-lg h-full w-full"
          // layout="constrained"
        />
      </div>

      <div className="flex flex-col justify-between max-w-xs px-4 py-4 gap-4 mx-auto">
        <div>
          <p className="cards-cta ">{heading}</p>
          <p className="navbar text-secondary mobile-paragraph pt-2">{title}</p>
        </div>
        <p className="cards ">{body}</p>
        <Link
          to={`/events/${slug}`}
          className="flex items-center justify-between mt-auto border-b hover:text-secondary"
        >
          <p className="cards mr-auto">Read More</p>
          <IconAdd />
        </Link>
        <div className="flex items-center justify-between card-text">
          <Link
            to="/form"
            className="flex items-center justify-center button-secondary desktop-button"
          >
            I'm in
          </Link>
          <ButtonShare slug={`https://thejuicypause.com/${slug}`} />
        </div>
      </div>
    </div>
  )
}

export const CardVerticalEvents = ({ title, heading, body, image, slug }) => {
  return (
    <div className="card-vertical shadow-md z-30 bg-mainBackground ">
      <GatsbyImage
        height={262}
        width={588}
        image={image}
        alt="image card"
        placeholder="tracedSVG"
        layout="constrained"
        className="rounded-t-lg block w-full"
      />

      <div className="flex flex-col justify-around max-w-xs px-4 py-4 gap-3 rounded-b-xl bg-mainBackground ">
        <p className="cards-cta">{heading}</p>
        <p className="navbar text-secondary mobile-paragraph">{title}</p>
        <p className="cards ">{body}</p>
        <Link
          to={`/events/${slug}`}
          className="flex items-center justify-between mt-auto border-b"
        >
          <p className="cards mr-auto">Read More</p>
          <IconAdd />
        </Link>
        <div className="flex items-center justify-between  card-text">
          <Link
            to="/form"
            className="button-secondary flex items-center justify-center"
          >
            I'm In
          </Link>
          <ButtonShare slug={`https://thejuicypause.com/${slug}`} />
        </div>
      </div>
    </div>
  )
}

export const CardHorizontalFriends = ({
  title,
  heading,
  body,
  image,
  slug,
  link,
}) => {
  console.log(link)
  return (
    <div className="card-horizontal shadow-md hidden lg:flex z-40">
      <div className="w-full">
        <GatsbyImage
          height={262}
          image={image}
          alt="image card"
          placeholder="tracedSVG"
          className="rounded-l-lg h-full w-full"
        />
      </div>

      <div className="flex flex-col justify-between max-w-xs px-4 py-4 gap-4 mx-auto">
        <div>
          <p className="cards-cta ">{heading}</p>
          <p className="navbar text-secondary mobile-paragraph pt-2">{title}</p>
        </div>
        <p className="cards ">{body}</p>
        <hr className="mt-auto" />
        <div className="flex items-center justify-between card-text">
          <a href={`https://${link}`}>
            <button className="button-secondary">Visit</button>
          </a>

          <ButtonShare slug={link} />
        </div>
      </div>
    </div>
  )
}

export const CardVerticalFriends = ({
  title,
  heading,
  body,
  image,
  slug,
  link,
}) => {
  return (
    <div className="card-vertical shadow-md z-10 bg-mainBackground">
      <GatsbyImage
        height={262}
        width={588}
        image={image}
        alt="image card"
        placeholder="tracedSVG"
        layout="constrained"
        className="rounded-t-lg block w-full"
      />

      <div className="flex flex-col justify-around max-w-xs px-4 py-4 gap-3 rounded-b-xl ">
        <p className="cards-cta">{heading}</p>
        <p className="navbar text-secondary mobile-paragraph">{title}</p>
        <p className="cards ">{body}</p>
        <hr className="mt-auto" />
        <div className="flex items-center justify-between card-text">
          <a href={`https://${link}`}>
            <button className="button-secondary">Visit</button>
          </a>

          <ButtonShare slug={link} />
        </div>
      </div>
    </div>
  )
}

export const CardEpisode = ({
  title,
  description,
  link,
  linkToDrive,
  image,
  slug,
}) => {
  return (
    <div className="snap-center card-vertical shadow-md min-w-xs flex-shrink-0 mb-2 ml-4 lg:ml-0">
      <GatsbyImage
        height={362}
        width={588}
        image={image}
        alt="image episode card"
        placeholder="tracedSVG"
        className="rounded-t-2xl w-full"
      />
      <div className="flex flex-col justify-around max-w-xs px-4 py-4 gap-4">
        <p className="navbar text-secondary mobile-paragraph">{title}</p>
        <p className="cards">{description}</p>

        <div className="flex items-center justify-between card-text">
          <a href={link}>
            <button className="button-secondary">Listen</button>
          </a>
          {linkToDrive == undefined ? (
            <ButtonShare slug={link} />
          ) : (
            <ButtonDownload slug={linkToDrive} />
          )}
        </div>
      </div>
    </div>
  )
}

export const CardHorizontalBlog = ({ title, date, desc, tags, image }) => {
  return (
    <div className="card-horizontal shadow-md flex justify-between bg-mainBackground animation-card">
      <GatsbyImage
        height={220}
        width={255}
        image={image}
        alt="image card"
        placeholder="tracedSVG"
        layout="constrained"
        className="rounded-l-2xl mx-auto w-full z-10 max-h-72"
      />
      <div className="flex flex-col justify-around max-w-xs pl-4 pr-2 py-2 gap-3 flex-shrink-0">
        <div>
          <p className="cards-cta pt-2 pb-2">{date.split("T")[0]}</p>
          <p className="navbar text-secondary mobile-paragraph ">{title}</p>
        </div>
        <p className="cards ">{desc}</p>

        {/* <div className="flex gap-2 flex-wrap pt-1 pb-2">
          <TagList tags={tags} />
        </div> */}
        <div className="flex items-center justify-between mt-auto">
          <p className="cards mr-auto">Read More</p>
          <IconAdd />
        </div>
      </div>
    </div>
  )
}

export const CardVerticalBlog = ({ title, date, desc, tags, image }) => {
  return (
    <div className="card-vertical shadow-md mt-auto z-20 bg-mainBackground ">
      <div className="block h-52">
        <GatsbyImage
          height={206}
          image={image}
          alt="image card"
          placeholder="tracedSVG"
          className="rounded-t-xl block h-full w-full z-10"
        />
      </div>
      <div className="flex flex-col justify-around max-w-xs pl-4 pr-2 pt-4 pb-2 gap-3 bg-mainBackground rounded-b-xl">
        <p className="cards-cta">{date.split("T")[0]}</p>
        <p className="navbar text-secondary mobile-paragraph">{title}</p>
        <p className="cards min-h-52">{desc}</p>

        <div className="flex items-center justify-between mt-auto">
          {/* <div className="flex gap-2 flex-wrap pt-1 pb-2">
            <TagList tags={tags} />
          </div> */}
          <p className="cards mr-auto">Read More</p>
          <IconAdd />
        </div>
      </div>
    </div>
  )
}
