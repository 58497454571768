import React from "react";
import { Link } from "gatsby";
import Layout from "../layout/Layout";
// import TagList from "../components/blog-posts/TagList";
import useBlogPost from "../utils/useBlogPost";
import { CardHorizontalBlog, CardVerticalBlog } from "../components/Cards";

export default function Tags({ pageContext }) {
  const { tag } = pageContext;
  const data = useBlogPost();
  // const tagString = JSON.stringify(tag);
  // const tagsArray = data.map((i) => i.tag);

  // const dataFiltered = data.filter((i) => JSON.stringify(i.tags) === tagString);

  // console.log(dataFiltered);

  return (
    <Layout>
      <div>
        <p>Posts tagged with "{tag}"</p>
        <div className="max-w-7xl mx-auto py-6 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-8">
          {data.map((i) => (
            <div className=" flex">
              <div className="xl:hidden grid mx-auto">
                <Link to={`/tags/${i.tags}`}>
                  <CardVerticalBlog
                    title={i.title}
                    date={i.date}
                    tags={i.tags}
                    desc={i.desc}
                    image={i.image}
                  />
                </Link>
              </div>

              <div className="hidden xl:grid lg:grid-cols-2 lg:items-center">
                <Link to={`/tags/${i.tags}`}>
                  <CardHorizontalBlog
                    title={i.title}
                    date={i.date}
                    tags={i.tags}
                    desc={i.desc}
                    image={i.image}
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
